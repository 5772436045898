.homepage {
    margin: auto;
}

.footer {
    background-color: black;
    color: white;
    position: absolute;
    min-width: 100%;
    text-align: center;
}

.footer-box {
    margin: auto;
    align-items: center;
}

.footer-box > p > a {
    color: white;
}