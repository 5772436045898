.abtUs {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin: auto;
    width: 70%;
    height: auto;
    border: #FE0100 2px solid;
    border-radius: 25px;
    padding: 2%;
    background-color: rgba(113, 121, 126, 0.3);
}

.summary {
    flex-direction: column;
    width: 60%;
}

.abtUs>img {
    width: 25%;
    height: auto;
}

.summary>p {
    margin: 10%;
}