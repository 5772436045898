div.contact {
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border: 2px solid #FE0100;
  border-radius: 25px;
  background-color: rgba(113,121,126,0.3);
}
.contact >p{
font-weight: bold;
}